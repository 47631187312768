export const { format: formatNumber } = Intl.NumberFormat("fr-CA", {
  notation: "compact",
  maximumFractionDigits: 1,
});

export const { format: formatCurrency } = Intl.NumberFormat("fr-CA", {
  style: "currency",
  currency: "CAD",
});

export const formatShortCurrency = (num: number) => {
  if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(0) + "k";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  }

  return formatCurrency(num);
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("fr-CA");
};

export const formatDateTime = (date: string) => {
  // Should return a date like "2021-09-01 14:30"
  return new Date(date).toLocaleString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export const relativeTime = (date: string) => {
  const newDate = new Date(date);
  const now = new Date();

  const diff = now.getTime() - newDate.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);

  if (minutes < 1) {
    return "à l'instant";
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 1) {
    return `il y a ${minutes} minutes`;
  }

  const days = Math.floor(hours / 24);
  if (days < 1) {
    return `il y a ${hours} heures`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 1) {
    return `il y a ${days} jours`;
  }

  const months = Math.floor(days / 30);
  if (months < 1) {
    return `il y a ${weeks} semaines`;
  }

  const years = Math.floor(months / 12);
  if (years < 1) {
    return `il y a ${months} mois`;
  }

  return `il y a ${years} ans`;
}


/* Role helpers */
export function isRole(currentUser: any, role: string) {
  return currentUser?.profileType === role;
}

export function isAdmin(currentUser: any) {
  return currentUser?.admin === true;
}

export function hasModule(currentUser: any, module: string) {
  if (!currentUser?.modules) {
    return false;
  }
  return currentUser?.modules.includes(module);
}

/* Analytics helpers */
export const translateEventName = (event: any) => {
  const names: { [key: string]: string } = {
    'acquisitionFiles/created': 'L\'opportunité a été créée',
    'acquisitionFiles/upload': `Le document "${event.properties?.acquisitionDocumentName}" a été uploadé`,
    'acquisitionFiles/generateLink': 'Un lien sécurisé a été généré',
    'acquisitionFiles/updated': 'L\'opportunité a été mise à jour',
    'acquisitionFiles/invite': `L'utilisateur "${event.properties?.invitedUser}" a été invité à consulter l'opportunité`,
    'acquisitionFiles/uninvite': `L'utilisateur "${event.properties?.invitedUser}" a été retiré du partage`,
    'acquisitionFiles/documents/created': `Le document "${event.properties?.document?.name}" a été créé`,
    'acquisitionFiles/documents/updated': `Le document "${event.properties?.document?.name}" a été ${event.properties?.action === 'name' ? 'renommé' : 'déplacé'}`,
    'acquisitionFiles/documents/deleted': `Le document "${event.properties?.document?.name}" a été supprimé`,
    'acquisitionFiles/documents/viewed': `Le document "${event.properties?.document?.name}" a été consulté`,
    'auth/validate': 'Connexion',
    'fiches/updated': 'La fiche a été mise à jour',
    'room/get': 'Conversation consultée',
    'listings/view': 'Listing consulté',
    'task/create': 'Tâche créée',
    'listings/like': 'Listing ajouté aux favoris',
    'listings/interest': 'Intérêt pour un listing',
    'listings/unlike': 'Listing retiré des favoris',
    'task/update': 'Tâche mise à jour',
    'calendar/callback': 'Calendrier synchronisé',
    'interests/accept': 'Intérêt accepté par un courtier',
    'interests/sendFinancialStatements': 'Documents financiers envoyés',
    'interests/delete': 'Intérêt supprimé',
    'projects/create': 'Projet créé',
    'room/sendMessage': 'Message envoyé',
    'room/deleteMessage': 'Message supprimé',
    'loi/create': 'LOI créée',
    'projects/assign': `Le projet a été réassigné`,
    'projects/updateDescription': `La description du projet a été mise à jour`,
    'comments/create': 'Un commentaire a été ajouté',
  }

  return names[event.event];
}


/**
 * Merge multiple statements data into one object
 */
export const mergedStatementData = (statementData: any) => {
  const result = {}

  statementData.forEach((entry) => {
    Object.keys(entry).forEach((category) => {
      Object.keys(entry[category]).forEach(year => {
        const value = entry[category][year];
        if (value !== null) {
          if (!result[category]) {
            result[category] = {};
          }
          if (!result[category][year]) {
            result[category][year] = value;
          }
        }
      })
    })
  })

  return result;
}

export function transformTreeValuesToArray(input: any) {
  if (!input) {
    return [];
  }
  return Object.keys(input)
    .filter(key => input[key].checked || input[key].partialChecked)
    .map(Number); // Ensure the keys are converted to numbers
}

export function transformArrayToTreeValues(input: any) {
  if (!input) {
    return {};
  }
  return input.reduce((acc: any, id: number) => {
    acc[id] = { checked: true };
    return acc;
  }, {});
}