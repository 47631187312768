<template>
    <Menubar :model="navBarItems" class="w-full sticky top-0 z-50 min-h-[53px]" :pt="menubarStyles">
        <template #start>
            <div class="flex items-center ml-6">
                <h3 class="text-lg font-bold">Discussions</h3>
                <NuxtLink to="https://chat.libechat.ca" target="_blank" @click="closeChat">
                    <Button icon="pi pi-external-link" text severity="secondary" class="ml-2" />
                </NuxtLink>
                <Button icon="pi pi-refresh" text severity="secondary" class="ml-2" @click="reloadChat" />
            </div>
        </template>
        <template #end>
            <div class="flex items-center">
                <Button icon="pi pi-times" text severity="secondary" class="ml-2" @click="closeChat" />
            </div>
        </template>
    </Menubar>
</template>

<script setup lang="ts">
const navBarItems = ref([]);
const menubarStyles = {
  root: "!border-l-0 !border-r-0 !border-t-0 !border-b min-h-14 !rounded-none !pl-0",
};

const chatOpened = useChatOpened();
const emits = defineEmits(['reloadChat']);

const closeChat = () => {
  chatOpened.value = false;
};

const reloadChat = () => {
  emits('reloadChat');
};
</script>
