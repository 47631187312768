<template>
  <div>
    <LayoutShell v-if="currentUser" :items="items" :return-to="backTo">
      <slot />
    </LayoutShell>
    <div v-else class="min-h-screen pt-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const currentUser = useCurrentUser();

const router = useRouter();
const backTo = router.currentRoute.value.query.backTo?.toString();
const id = router.currentRoute.value.params.id;
const currentAcquisitionFile = useCurrentAcquisitionFile();
const allItems = () => [
  {
    label: "Retour",
    icon: "pi pi-arrow-left",
    to: backTo ? backTo : "/acquisitionFiles",
    command: () => {
      currentAcquisitionFile.value = '';
    }
  },
  {
    label: "Tableau de bord",
    icon: "pi pi-home",
    to: `/acquisitionFiles/${id}/dashboard`,
  },
  {
    label: "Documents",
    icon: "pi pi-file",
    to: `/acquisitionFiles/${id}/`,
  },
  {
    label: "Gestion de projet",
    icon: "pi pi-briefcase",
    to: `/acquisitionFiles/${id}/project`,
    condition: currentUser.value?.admin === true,
  },
  {
    label: "Finances",
    icon: "pi pi-money-bill",
    to: `/acquisitionFiles/${id}/finances`,
  },
  {
    label: "Activité",
    icon: "pi pi-chart-line",
    to: `/acquisitionFiles/${id}/activity`,
  },
  {
    label: "Paramètres",
    icon: "pi pi-cog",
    to: `/acquisitionFiles/${id}/settings`,
  },
];
const items = computed(() => {
  return allItems().filter((item) => {
    return item.condition !== undefined ? item.condition : true;
  });
});

watch(currentUser, () => {
  if (currentUser.value) {
    items.value = allItems().filter((item) => item.condition === true);
  }
});
</script>
