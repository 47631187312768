import { ref, computed } from 'vue';

export interface Notification {
  id: number;
  userId: number;
  title: string;
  message: string;
  type: string;
  icon: string;
  iconColor: string;
  read: boolean;
  link?: string;
  createdAt: Date;
  updatedAt: Date;
}

export const useNotifications = () => {
  const notifications = ref<Notification[]>([]);
  const loading = ref(false);

  const unreadCount = computed(() => {
    const count = notifications.value.filter(n => !n.read).length;
    return count > 0 ? count : null;
  });

  const hasUnread = computed(() => unreadCount.value > 0);

  const fetchNotifications = async () => {
    loading.value = true;
    try {
      const { data } = await useFetch('/api/notifications');
      if (data.value) {
        notifications.value = data.value;
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      loading.value = false;
    }
  };

  const markAsRead = async (notificationId: number) => {
    try {
      await useFetch(`/api/notifications/${notificationId}/read`, {
        method: 'POST'
      });
      const notification = notifications.value.find(n => n.id === notificationId);
      if (notification) {
        notification.read = true;
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      await useFetch('/api/notifications/read-all', {
        method: 'POST'
      });
      notifications.value.forEach(notification => {
        notification.read = true;
      });
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  // Initial fetch
  onMounted(() => {
    fetchNotifications();
  });

  return {
    notifications,
    loading,
    unreadCount,
    hasUnread,
    fetchNotifications,
    markAsRead,
    markAllAsRead
  };
}; 