<template>
  <div>
    <LayoutShell v-if="currentUser" :items="items">
      <slot />
    </LayoutShell>
    <LayoutAnonymousHeader v-else-if="anonymous" />
    <slot v-else />
  </div>
</template>

<script setup lang="ts">
const anonymous = useAnonymous();
const currentUser = useCurrentUser();

const allItems = () => [
  {
    label: "Tableau de bord",
    icon: "pi pi-th-large",
    to: "/",
  },
  {
    label: "Entreprises à vendre",
    icon: "pi pi-building",
    to: "/entreprises",
    condition: hasModule(currentUser.value, 'find-business')
  },
  // {
  //     label: "Discussions",
  //     icon: "pi pi-comment",
  //     to: "/discussions",
  //     tag: notifications?.length > 0 ? notifications.length : null,
  // },
  // {
  //     label: "Mes favoris",
  //     icon: "pi pi-heart",
  //     to: "/account/favourites",
  //     condition: currentUser.value?.fields["Type de profil"] === "Repreneur",
  // },
  // {
  //     label: "Mes intérêts",
  //     icon: "pi pi-star",
  //     to: "/interests",
  //     condition: currentUser.value?.fields["Type de profil"] === "Repreneur",
  // },
  {
    label: "Curriculum",
    icon: "pi pi-calendar",
    to: "/curriculum",
    condition: hasModule(currentUser.value, 'curriculum'),
  },
  {
    label: "Formations",
    icon: "pi pi-book",
    to: "/formations",
    condition: hasModule(currentUser.value, 'formations'),
  },
  {
    label: "Opportunités",
    icon: "pi pi-bullseye",
    to: "/acquisitionFiles",
    condition: hasModule(currentUser.value, 'datarooms'),
  },
  // {
  //   label: "Prospection",
  //   icon: "pi pi-address-book",
  //   to: "/prospection",
  //   disabled: true
  // },
  {
    label: "Projets",
    icon: "pi pi-briefcase",
    to: "/projects",
    condition: isAdmin(currentUser.value),
  },
  {
    label: "Outils",
    icon: "pi pi-wrench",
    to: "/outils",
    condition: hasModule(currentUser.value, 'tools'),
  },
  {
    label: "Administration",
    icon: "pi pi-cog",
    condition: isAdmin(currentUser.value),
    items: [
      {
        label: "Pipeline parcours client",
        icon: "pi pi-users",
        to: "/admin/clients",
        child: true,
        disabled: true,
      },
      {
        label: "Pipeline courtage",
        icon: "pi pi-wallet",
        to: "/pipelines/66cdf13b029a2d37126223f5",
        disabled: true,
      },
      {
        label: "Utilisateurs",
        icon: "pi pi-users",
        to: "/admin/users",
        child: true,
      },
      {
        label: "Log book",
        icon: "pi pi-book",
        to: "/admin/logbook",
        child: true,
        disabled: true,
      },
      {
        label: "Form Valorisation",
        url: "https://app.libe.quebec/evaluation",
        target: "_blank",
        icon: "pi pi-external-link",
        child: true,
      },
      {
        label: "Calc. valorisation",
        url: "https://docs.google.com/spreadsheets/d/1O6nYN4j2oB9VYPPmnExqp8jxF8uZi1ggH7sIreOuGw4/edit?usp=sharing",
        target: "_blank",
        icon: "pi pi-external-link",
        child: true,
      },
      {
        label: "KPI Closer",
        url: "https://docs.google.com/spreadsheets/d/1WHlyi5PbeqvqpxbHqJSDANfb9m7b1Pi7-TB5LXpyCJ0/edit?usp=sharing",
        target: "_blank",
        icon: "pi pi-external-link",
        child: true,
      },
    ],
  },
  {
    label: "Analytics",
    icon: "pi pi-chart-bar",
    to: "/analytics",
    condition: currentUser.value?.admin === true,
    disabled: true,
  },
];
const items = computed(() => {
  return allItems().filter((item) => {
    return item.condition !== undefined ? item.condition : true;
  });
});

watch(currentUser, () => {
  if (currentUser.value) {
    items.value = allItems().filter((item) => item.condition);
  }
});
</script>
