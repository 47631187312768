export const useCurrentUser = () => {
  return useState<string>("currentUser", () => {
    return null
  });
};

export const useCurrentFolder = () => {
  return useState<string>("currentFolder", () => {
    return null
  });
}

export const useSocket = () => {
  return useState("socket", () => {
    return null
  });
}

export const useAnonymous = () => {
  return useState<string>("anonymous", () => {
    return null
  });
}

export const useCurrentAcquisitionFile = () => {
  return useState<string>("currentAcquisitionFile", () => {
    return null
  });
}

export const useChatOpened = () => {
  return useState<boolean>("chatOpened", () => {
    return false
  });
}