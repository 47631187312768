<template>
  <div class="relative">
    <Button
      v-tooltip.bottom="'Notifications'"
      icon="pi pi-bell"
      text
      severity="secondary"
      class="p-button-rounded"
      badge-class="p-badge-danger absolute !-right-[-5px] !-top-[0px] !-z-100"
      :badge="unreadCount?.toString()"
      :pt="{ root: '!gap-0 relative !overflow-visible', label: '!hidden' }"
      @click="toggleMenu"
    />
    <Popover
      ref="menu"
      :pt="{ content: '!p-0' }"
      append-to="body"
    >
      <div class="w-[300px]">
        <div class="p-3 border-b border-surface-200 dark:border-surface-700 font-bold flex items-center justify-between">
          <span>Notifications</span>
          <Button 
            v-if="hasUnread"
            label="Tout marquer comme lu" 
            link 
            size="small"
            @click="handleMarkAllAsRead"
          />
        </div>
        <div v-if="notifications.length === 0" class="p-3 text-center text-sm text-surface-400">
          Aucune notification
        </div>
        <div v-else class="max-h-[400px] overflow-y-auto">
          <NotificationItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            @click="handleNotificationClick(notification)"
          />
        </div>
      </div>
    </Popover>
  </div>
</template>

<script setup lang="ts">
const router = useRouter();
const menu = ref(null);

const { 
  notifications, 
  loading, 
  unreadCount, 
  hasUnread, 
  markAsRead, 
  markAllAsRead 
} = useNotifications();

const toggleMenu = (event) => {
  menu.value?.toggle(event);
};

const handleNotificationClick = async (notification) => {
  await markAsRead(notification.id);
  if (notification.link) {
    router.push(notification.link);
  }
  menu.value?.hide();
};

const handleMarkAllAsRead = async () => {
  await markAllAsRead();
  menu.value?.hide();
};
</script> 